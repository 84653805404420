/**
 * Use the vercel_env if we're in dev or prod, if we're in preview,
 * use beta or staging if they apply, otherwise, preview.
 */
export const sentryEnv = () => {
  const vercelEnv =
    process.env.VERCEL_ENV || process.env.NEXT_PUBLIC_VERCEL_ENV;

  if (vercelEnv !== "preview") {
    return vercelEnv || "development";
  }

  const branch =
    process.env.VERCEL_GIT_COMMIT_REF ||
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF;

  if (branch === "beta" || branch === "staging") {
    return branch;
  }

  return vercelEnv;
};
