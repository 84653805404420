globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"45af10700f0d5367c677164a2320282c373aff73"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { init, replayIntegration } from "@sentry/nextjs";
import { sentryEnv } from "utils/sentry_config";

// Don't record any development sessions...
const integrations =
  sentryEnv() === "development"
    ? []
    : [
        replayIntegration({
          // Additional Replay configuration goes in here, for example:
          maskAllText: false,
          blockAllMedia: false,
          stickySession: true,
        }),
      ];

if (sentryEnv() !== "development") {
  init({
    dsn: "https://b91d352b9942860a20e30b313d89444b@o4505647415492608.ingest.us.sentry.io/4505647416672256",

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,

    replaysOnErrorSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production

    // We're going to control sampling manually, so don't automatically record
    // any samples.
    replaysSessionSampleRate: 0.0,

    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    integrations: integrations,

    environment: sentryEnv(),
    release: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || "development",
    dist: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || "main",
  });
}
